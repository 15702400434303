import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueMeta from "vue-meta";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { BIconX, BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import JQuery from "jquery";
import VueGtag from "vue-gtag";

library.add(faTwitter, faBars, faTimes);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component('BIconX', BIconX)
Vue.use(VueGtag, {
  config: { id: "G-0T20SLXD9R" },
  includes: [
    { id: 'G-0T20SLXD9R' }
  ]
}, router);

new Vue({
  router,
  store,
  axios,
  JQuery,
  render: (h) => h(App),
}).$mount("#app");
