import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/tour-management",
    name: "tour-management",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home.vue"),
    props: true
  },
  {
    path: "/venue-management",
    name: "venue-management",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home.vue"),
    props: true
  },
  {
    path: "/about-us",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home.vue"),
    props: true
  },
  {
    path: "/contact-us",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home.vue"),
    props: true
  },
];

const router = new VueRouter({
  routes
});

export default router;
