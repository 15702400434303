<template>
  <div class="blue" :style="'background-image:url(' + blue + ');'">
    <b-container>
      <div class="request-a-callback-form">
        <transition name="fade" mode="out-in">
          <div class="thanksBox" v-show="sent">
            <p class="thanksText">Thanks for contacting us, we will get back to you shortly...</p>
          </div>
        </transition>
        <template v-if="!sent">
          <b-form @submit="onSubmit">
            <b-row>
              <b-col md="6" cols="12" class="mobPad">
                <b-form-input
                  type="text"
                  v-model="names"
                  placeholder="Your Name"
                />
                <br />
                <br />
                <b-form-input
                  type="text"
                  v-model="email"
                  placeholder="Email Address"
                />
                <br />
                <br />
                <b-form-input
                  type="text"
                  v-model="phone"
                  placeholder="Phone Number"
                />
                <br />
                <br />

                <b-form-input
                  type="text"
                  v-model="subject"
                  placeholder="Subject"
                />
              </b-col>
              <b-col md="6" cols="12">
                <b-form-textarea
                  v-model="message"
                  rows="11"
                  cols="1"
                  class="form-control"
                >
                </b-form-textarea>
                <br />
                <br />
                <button
                  data-text="submit"
                  type="submit"
                  class="moreBtn"
                >
                  Submit
                </button>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </div>
    </b-container>
  </div>
</template>

<style scoped>
.thanksBox {
  text-align: center;
  border: 5px solid #fff;
  margin-top: -2em;
}
.thanksText {
  font-size: 18pt;
  color: #fff;
  font-weight: bold;
  margin-top: 1rem;
}
.blue {
  background-size: cover;
  padding-bottom: 5%;
  padding-top: 7%;
}

.moreBtn {
  border-color: transparent;
  font-size: 1.5rem;
  background-color: #00aeef;
  color: #f4f4f6;
  font-weight: 300;
  padding: 10px 75px;
  border-radius: 0;
}

::v-deep .form-control{
  font-size:18px
}
@media only screen and (max-width: 480px) {
  .mobPad{
    padding-bottom: 50px;
  }
  .moreBtn {
    border-color: transparent;
    font-size: 1.5rem;
    background-color: #00aeef;
    color: #f4f4f6;
    font-weight: 300;
    padding: 10px 75px;
    border-radius: 0;
    text-align: center;
    margin: auto;
    display: block;
    margin-bottom: 20px;
  }
}
</style>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      sent: false,
      contact: true,
      names: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    };
  },
  props: ["blue"],
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("names", this.names);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("subject", this.subject);
      formData.append("message", this.message);
      console.log(formData);
      axios
        .post(
          "https://wp-ocssport.ocs-sport.com/wp-json/contact-form-7/v1/contact-forms/109/feedback",
          formData
        )
        .then((response) => {
          this.sent = true;
          return response;
        });
    },
  },
};
</script>
