<template>
  <div class="home">
    <Splash :acf="acf" :id="wpPageId" />

    <template
      v-if="
        wpPageId === 4 ||
          wpPageId === 201 ||
          wpPageId === 158 ||
          wpPageId === 12
      "
    >
      <section class="secPad">
        <SectionBlock :acf="acf" :id="wpPageId" sec="right" />
      </section>
    </template>

    <template v-if="wpPageId === 4 || wpPageId === 201 || wpPageId === 158">
      <section class="secPad grey">
        <SectionBlock :acf="acf" :id="wpPageId" sec="left" />
      </section>
    </template>

    <template v-if="wpPageId === 201 || wpPageId === 158">
      <section class="secPad">
        <SectionBlock :acf="acf" :id="wpPageId" sec="right_1" />
      </section>
    </template>

    <template v-if="wpPageId === 201">
      <section class="secPad grey">
        <SectionBlock :acf="acf" :id="wpPageId" sec="left_1" />
      </section>
    </template>

    <template v-if="wpPageId === 4">
      <section class="secPad">
        <SectionBlock :acf="acf" :id="wpPageId" sec="centre" />
        <b-container>
          <div class="ctaTop"></div>
          <a :href="'tel:' + acf.acf.phone_number">
            <b-img :src="acf.acf.alps_logo"></b-img>
          </a>
        </b-container>
      </section>
    </template>

    <template v-if="wpPageId === 201">
      <OCS />
    </template>

    <template
      v-if="
          wpPageId === 158 ||
          wpPageId === 14
      "
    >
    <Form :blue="acf.acf.golf_ball_blue"/>
    </template>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import SectionBlock from "@/components/sections.vue";
import Splash from "@/components/splash.vue";
import Form from "@/components/form.vue";
import Footer from "@/components/footer.vue";
import OCS from "@/components/ocs-systems.vue";
export default {
  name: "Home",
  components: { Splash, SectionBlock, Footer, Form, OCS },
  data: function() {
    return {
      acf: [],
    };
  },
  computed: {
    wpPageId: function(page) {
      if (this.$route.name === "home") page = 4;
      else if (this.$route.name === "tour-management") page = 201;
      else if (this.$route.name === "venue-management") page = 158;
      else if (this.$route.name === "contact") page = 14;
      else if (this.$route.name === "about") page = 12;
      return page;
    },
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_API_URL + "/wp-json/wp/v2/pages/" + this.wpPageId
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.acf = response.data)); //API data wanted pulled out
  },
};
</script>

<style scoped>
::v-deep .containerSep {
  max-width: 1400px;
  padding: 0;
}
.secPad {
  padding: 100px;
  padding-top: 110px;
  padding-bottom: 110px;
}
.grey {
  background-color: #f4f4f6;
}
.cta {
  background-repeat: no-repeat;
  background-size: cover;
}
.ctaTop {
  padding: 25px;
}
.ctaPad {
  padding: 68px;
}
.mobSize {
  display: none;
}
@media only screen and (max-width: 1260px) {
  .lgSize {
    display: none;
  }
  .mobSize {
    display: block;
  }
  .ctaPadMob {
    padding: 150px 254px 100px 60px;
  }
}
@media only screen and (max-width: 1024px) {
  .ctaPadMob {
    padding: 115px 254px 100px 60px;
  }
}
@media only screen and (max-width: 768px) {
  .secPad {
    padding: 50px;
  }
  .ctaPadMob {
    padding: 125px 100px 100px 45px;
  }
}

@media only screen and (max-width: 480px) {
  .secPad {
    padding: 25px;
  }
  .ctaPadMob {
    padding: 125px 35px 100px 45px;
  }
}
</style>
