<template>
  <div>
    <b-jumbotron
      class="splash"
      :style="'background-image: url(' + acf.acf.splash + ');'"
    >
      <div class="ocsLogoWrap">
        <b-img
          class="ocsLogo"
          src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/03/OCS__Sport_Logo_2017_WO.png"
        ></b-img>
      </div>
      <div class="menu">
        <b-button v-b-toggle.sidebar-no-header
          ><font-awesome-icon id="menuOpen" class="icon" :icon="['fa', 'bars']"
        /></b-button>
        <b-sidebar
          id="sidebar-no-header"
          aria-labelledby="sidebar-no-header-title"
          no-header
          shadow
        >
          <template #default>
            <div class="header">
              <b-row>
                <b-col>
                  <b-img
                    class="logo"
                    src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/03/OCS__Sport_Logo_2017_WO.png"
                  ></b-img>
                </b-col>
                <b-col>
                  <b-button class="close" v-b-toggle.sidebar-no-header
                    ><b-icon-X
                      id="menuOpen"
                      class="icon"
                      :icon="['fa', 'times']"
                  /></b-button>
                </b-col>
              </b-row>
            </div>
            <div>
              <nav class="mb-3">
                <b-nav vertical>
                  <b-nav-item to="/" :class="{ current: id === 4 }" v-b-toggle.sidebar-no-header
                    >Home</b-nav-item
                  >
                  <b-nav-item
                    to="/tour-management"
                    :class="{ current: id === 201 }" v-b-toggle.sidebar-no-header
                    >Tour Management</b-nav-item
                  >
                  <b-nav-item
                    to="/venue-management"
                    :class="{ current: id === 158 }" v-b-toggle.sidebar-no-header
                    >Venue Management</b-nav-item
                  >
                  <b-nav-item to="/about-us" :class="{ current: id === 12 }" v-b-toggle.sidebar-no-header
                    >About Us</b-nav-item
                  >
                  <b-nav-item to="/contact-us" :class="{ current: id === 14 }" v-b-toggle.sidebar-no-header
                    >Contact Us</b-nav-item
                  >
                  <b-row class="supportNav_group">
                    <b-col lg="4" md='4' sm="4" xs="4">
                      <b-link href="https://ocs-digital.com/" target="_blank">
                        <img class="supportLogosNav" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/05/OCs_Digital_2023_LogoBLUE_V1-1.png">
                      </b-link>
                    </b-col>
                    <b-col lg="4" md='4' sm="4" xs="4">
                      <b-link href="https://ocs-studios.com/" target="_blank">
                        <img class="supportLogosNav studioesLogo" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/05/OCS_Studios_512x512.png">
                      </b-link>
                    </b-col>
                  </b-row>
                </b-nav>
              </nav>
            </div>
          </template>
        </b-sidebar>
      </div>
      <b-container>
        <b-row>
          <template v-if="id === 14">
          <b-col lg="12" md="12">
            <h1>
              <span class="white">
                {{ acf.acf.splash_title }}
              </span>
              <br />
              <span class="blue">
                {{ acf.acf.splash_title_1 }}
              </span>
              <br />
              <span class="white">
                {{ acf.acf.splash_title_2 }}
              </span>
              <br />
            </h1>
          </b-col>
          </template>
          <b-col v-else lg="6" md="12">
            <h1>
              <span class="white">
                {{ acf.acf.splash_title }}
              </span>
              <br />
              <span class="blue">
                {{ acf.acf.splash_title_1 }}
              </span>
              <br />
              <span class="white">
                {{ acf.acf.splash_title_2 }}
              </span>
              <br />
              <template v-if="id === 4">
                <b-button class="splashBtn" to="/tour-management"
                  >Discover Our Technology</b-button
                >
              </template>
            </h1>
          </b-col>
          <b-col lg="6" class="mobHide">
            <b-img class="splashSubImg" :src="acf.acf.splash_image"></b-img>
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: "Splash",
  props: ["acf", "id"],
};
</script>

<style scoped>
.supportNav_group {
  margin-top: 30px;
  text-align: center;
  margin-left: -8px;
}
.studioesLogo {
  margin-top: -8px;
}
img.supportLogosNav {
  width: 60px;
}
.splash {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 800px;
  background-repeat: no-repeat;
  background-position: 50% 45%;
  background-size: cover;
  border-radius: 0;
  padding-top: 195px;
}
.white {
  color: #fff;
}
.blue {
  color: #00aeef;
  font-weight: 500;
}
h1 {
  font-size: 75px;
  font-weight: 100;
}
.splashBtn {
  border-color: transparent;
  font-size: 1.5rem;
  background-color: #00aeef;
  color: #f4f4f6;
  font-weight: 300;
  padding: 10px 75px;
  border-radius: 0;
}
.splashBtn:hover{
  background-color: #003460;
}
.splashSubImg {
  margin-top: -25px;
}
.menu {
  position: absolute;
  top: 0;
  left: 0;
}
.menu > button {
  background-color: #00aeef;
  border: 0;
  border-radius: 0;
  font-size: 25px;
}
::v-deep .close {
    color: #fff !important;
    font-size: 55px !important;
    margin-right: -10px;
    opacity: 1;
    padding: 0px 10px;
}
::v-deep .close:hover{
      background-color: transparent;
    border-color: transparent;
    border: 0;
}
.header {
  background-color: #00aeef;
}
::v-deep li {
  list-style: none;
}
.logo {
  width: 80px;
}
.nav-link {
  color: #333333;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  display: block;
  padding: 13px 18px 12px;
}
.nav-item:hover {
  background-color: #00aeef;
}
.nav-item:hover > a {
  color: #f4f4f6 !important;
}
.current {
  
  background-color: #00aeef !important;
}
.current > a {
  font-weight: 600 !important;
  color: #f4f4f5!important;
}
.ocsLogoWrap {
  position: absolute;
  top: 5px;
  right: 10px;
}
.ocsLogo {
  width: 125px;
}

::v-deep .b-sidebar-body{
background-color: #fff;
}
@media only screen and (max-width: 992px) {
  .mobHide {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .mobHide {
    display: block!important;
  }
  .supportNav_group {
    margin-top: 30px;
    text-align: left;
    margin-left: 5px;
  }
  .studioesLogo {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 480px) {
  h1 {
    font-size: 55px;
  }
  .splashBtn {
    margin-top: 60px;
    padding: 10px 69px !important;
  }
}
</style>
