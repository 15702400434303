<template>
  <div class="ocs-systems">
      <section class="secPad">
        <h2 class="textTitle">OCS Systems</h2>
        <div class="containerSep">
          <b-row>
            <b-col lg="4" sm="12">
              <div class="outerCard">
                <div class="headerSecion">
                    <h3 class="headerTitle">OCS Lite</h3>
                    <p class="headerPara">For small up and coming Pro & Amateur golf tours</p>
                </div>
                <div class="priceSecion">
                    <p class="moneyText">From <span class="boldMoney">£500</span> pcm</p>
                </div>
                <div class="contentSection">
                    <ul>
                        <li class="listService">Up to 4 admin users</li>
                        <li class="listService">Player System</li>
                        <li class="listService">Tournament System</li>
                        <li class="listService">Live Scoring</li>
                        <li class="listService">Order of Merit features</li>
                        <li class="listService">Template website</li>
                        <li class="listService">Email support </li>
                        <li class="listService">Optional App*</li>
                        <span class="litePermonth">*From £1,000 per month</span>
                    </ul>
                </div>
                <div class="enquiryForm" v-b-modal.modal-center>
                    <p class="enquireToday">Enquire Today</p>
                </div>
                <b-modal size="xl" id="modal-center" centered>
                    <LiteFrom :blue="acf.acf.golf_ball_blue"/>
                </b-modal>
              </div>
            </b-col>
            <b-col lg="4" sm="12">
              <div class="outerCard">
                <div class="headerSecion-Tour">
                    <h3 class="headerTitle">OCS Tour</h3>
                    <p class="headerPara">For medium sized Pro & Amateur golf tours</p>
                </div>
                <div class="priceSecion">
                    <p class="moneyText">From <span class="boldMoney">£2,500</span> pcm</p>
                </div>
                <div class="contentSection">
                    <ul>
                        <li class="listService">Up to 15 admin users</li>
                        <li class="listService">Member System</li>
                        <li class="listService">Marketing System</li>
                        <li class="listService">Golf Club Database</li>
                        <li class="listService">Subscription featured</li>
                        <li class="listService">Statistics System</li>
                        <li class="listService">Tournament Management </li>
                        <li class="listService">Real Time Live Scoring</li>
                        <li class="listService">Order of Merit features</li>
                        <li class="listService">Bespoke Theme Website</li>
                        <li class="listService">Dedicated Support</li>
                        <li class="listService">IOS & Android App</li>
                        <li class="listService">Onsite Tools - Scoreboard Apps</li>
                    </ul>
                </div>
                <div class="enquiryForm-Tour" v-b-modal.modal-centerTour>
                    <p class="enquireToday">Enquire Today</p>
                </div>
                <b-modal size="xl" id="modal-centerTour" centered>
                    <TourForm :blue="acf.acf.golf_ball_blue"/>
                </b-modal>
              </div>
            </b-col>
            <b-col lg="4" sm="12">
              <div class="outerCard">
                <div class="headerSecion-Elite">
                    <h3 class="headerTitle">OCS Elite</h3>
                    <p class="headerPara">For large professional golf tours</p>
                </div>
                <div class="priceSecion">
                    <p class="moneyText-Elite">Price on Application</p>
                </div>
                <div class="contentSection">
                    <ul>
                        <li class="listService">Unlimited Admin Users</li>
                        <li class="listService">Member System</li>
                        <li class="listService">Caddy System</li>
                        <li class="listService">Player Manager System</li>
                        <li class="listService">Volunteers Database</li>
                        <li class="listService">Accreditation System</li>
                        <li class="listService">Marketing System</li>
                        <li class="listService">Golf Club Database</li>
                        <li class="listService">Subscription Features</li>
                        <li class="listService">Statistics System</li>
                        <li class="listService">Tournament Management</li>
                        <li class="listService">Real Time Live Scoring</li>
                        <li class="listService">Order of Merit features</li>
                        <li class="listService">Bespoke Website - World Wide CDN</li>
                        <li class="listService">External API's</li>
                        <li class="listService">Real Time TV Data Feeds</li>
                        <li class="listService">Co Sanctioned Integration</li>
                        <li class="listService">Dedicated Support </li>
                        <li class="listService">IOS & Android App </li>
                        <li class="listService">Onsite Tools - Scoreboard Apps</li>
                        <li class="listService">+ Lots more</li>
                    </ul>
                </div>
                <div class="enquiryForm-Elite" v-b-modal.modal-centerElite>
                    <p class="enquireToday">Enquire Today</p>
                </div>
                <b-modal size="xl" id="modal-centerElite" centered>
                    <EliteForm :blue="acf.acf.golf_ball_blue"/>
                </b-modal>
              </div>
            </b-col>
          </b-row>
        </div>
      </section>
  </div>
</template>

<script>
import axios from "axios";
import LiteFrom from "@/components/liteForm.vue";
import TourForm from "@/components/tourForm.vue";
import EliteForm from "@/components/eliteForm.vue";
export default {
  name: "Home",
  components: { 
    LiteFrom, 
    TourForm, 
    EliteForm, 
  },
  data: function() {
    return {
      acf: [],
    };
  },
  computed: {
    wpPageId: function(page) {
      if (this.$route.name === "home") page = 4;
      else if (this.$route.name === "tour-management") page = 201;
      else if (this.$route.name === "venue-management") page = 158;
      else if (this.$route.name === "contact") page = 14;
      else if (this.$route.name === "about") page = 12;
      return page;
    },
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_API_URL + "/wp-json/wp/v2/pages/" + this.wpPageId
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.acf = response.data)); //API data wanted pulled out
  },
};
</script>

<style scoped>
::v-deep .close {
  float: right;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
}
::v-deep .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  background: transparent;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px;
  border-radius: 0.3rem;
  outline: 0;
}
::v-deep .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0;
}
::v-deep footer#modal-center___BV_modal_footer_ {
  display: none;
}
::v-deep footer#modal-centerTour___BV_modal_footer_ {
  display: none;
}
::v-deep footer#modal-centerElite___BV_modal_footer_ {
  display: none;
}
li {
  list-style: none;
}
li::before {
  content: '';
  display: inline-block;
  height: 11px;
  width: 30px;
  background-image: url(https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/04/Arrow_2.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.textTitle {
  color: #212529;
  font-size: 2rem; 
  text-align: center;
  margin-bottom: 50px;
}
.contentSection {
  background-color: #f4f4f6;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -15px;
  height: 700px;
}
.enquiryForm {
  background-color: #4BACE9;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding: 10px;
}
.enquiryForm:hover {
  background-color: #003460;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.enquiryForm-Tour {
  background-color: #3F96CC;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding: 10px;
}
.enquiryForm-Tour:hover {
  background-color: #003460;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.enquiryForm-Elite {
  background-color: #1E6795;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding: 10px;
}
.enquiryForm-Elite:hover {
  background-color: #003460;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
p.enquireToday {
  margin-bottom: 0px;
}
span.litePermonth {
  font-size: 9px;
}
li.listService {
  color: #000000;
  font-size: 14px;
  padding-bottom: 10px;
}
span.boldMoney {
  font-weight: bold;
  font-size: 28px;
}
p.moneyText {
  font-size: 11px;
  text-transform: uppercase;
}
p.moneyText-Elite {
  font-size: 18px;
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 7px;
}
.priceSecion {
  background-color: #FFC301;
  color: #fff;
  text-align: center;
}
h3.headerTitle {
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
}
p.headerPara {
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
}
.headerSecion-Elite {
  background-color: #1E6795;
  border-radius: 10px 10px 0px 0px;
  padding: 25px;   
  min-height: 124px;
}
.headerSecion-Tour {
  background-color: #3F96CC;
  border-radius: 10px 10px 0px 0px;
  padding: 25px;   
  min-height: 124px;
}
.headerSecion {
  background-color: #4BACE9;
  border-radius: 10px 10px 0px 0px;
  padding: 25px;
  min-height: 124px;
}
::v-deep .containerSep {
  max-width: 1400px;
  /* padding: 0; */
  margin: auto;
}
.secPad {
  padding: 100px;
  padding-top: 110px;
  padding-bottom: 110px;
}
.grey {
  background-color: #f4f4f6;
}
.cta {
  background-repeat: no-repeat;
  background-size: cover;
}
.ctaTop {
  padding: 25px;
}
.ctaPad {
  padding: 68px;
}
.mobSize {
  display: none;
}
@media only screen and (max-width: 1260px) {
  .lgSize {
    display: none;
  }
  .mobSize {
    display: block;
  }
  .ctaPadMob {
    padding: 150px 254px 100px 60px;
  }
}
@media only screen and (max-width: 1024px) {
  .ctaPadMob {
    padding: 115px 254px 100px 60px;
  }
}
@media only screen and (max-width: 990px) {
  .outerCard {
    margin-bottom: 30px;
  }
  .contentSection {
    background-color: #f4f4f6;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -15px;
    height: auto;
  }
}
@media only screen and (max-width: 768px) {
  .secPad {
    padding: 50px;
  }
  .ctaPadMob {
    padding: 125px 100px 100px 45px;
  }
}

@media only screen and (max-width: 480px) {
  .secPad {
    padding: 25px;
  }
  .ctaPadMob {
    padding: 125px 35px 100px 45px;
  }
}
</style>
