var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('Splash',{attrs:{"acf":_vm.acf,"id":_vm.wpPageId}}),(
      _vm.wpPageId === 4 ||
        _vm.wpPageId === 201 ||
        _vm.wpPageId === 158 ||
        _vm.wpPageId === 12
    )?[_c('section',{staticClass:"secPad"},[_c('SectionBlock',{attrs:{"acf":_vm.acf,"id":_vm.wpPageId,"sec":"right"}})],1)]:_vm._e(),(_vm.wpPageId === 4 || _vm.wpPageId === 201 || _vm.wpPageId === 158)?[_c('section',{staticClass:"secPad grey"},[_c('SectionBlock',{attrs:{"acf":_vm.acf,"id":_vm.wpPageId,"sec":"left"}})],1)]:_vm._e(),(_vm.wpPageId === 201 || _vm.wpPageId === 158)?[_c('section',{staticClass:"secPad"},[_c('SectionBlock',{attrs:{"acf":_vm.acf,"id":_vm.wpPageId,"sec":"right_1"}})],1)]:_vm._e(),(_vm.wpPageId === 201)?[_c('section',{staticClass:"secPad grey"},[_c('SectionBlock',{attrs:{"acf":_vm.acf,"id":_vm.wpPageId,"sec":"left_1"}})],1)]:_vm._e(),(_vm.wpPageId === 4)?[_c('section',{staticClass:"secPad"},[_c('SectionBlock',{attrs:{"acf":_vm.acf,"id":_vm.wpPageId,"sec":"centre"}}),_c('b-container',[_c('div',{staticClass:"ctaTop"}),_c('a',{attrs:{"href":'tel:' + _vm.acf.acf.phone_number}},[_c('b-img',{attrs:{"src":_vm.acf.acf.alps_logo}})],1)])],1)]:_vm._e(),(_vm.wpPageId === 201)?[_c('OCS')]:_vm._e(),(
        _vm.wpPageId === 158 ||
        _vm.wpPageId === 14
    )?[_c('Form',{attrs:{"blue":_vm.acf.acf.golf_ball_blue}})]:_vm._e(),_c('Footer')],2)}
var staticRenderFns = []

export { render, staticRenderFns }