<template>
  <div>
    <div>
      <b-container>
        <template v-if="sec === 'right'">
          <b-row>
            <b-col lg="6" md="12" order-lg="1" order-md="2" order="2">
              <div class="textSec">
                <h2 class="textTitle">
                  {{ acf.acf.title }}
                </h2>
                <h3 class="textSub">
                  {{ acf.acf.sub_title }}
                </h3>
                <span class="text" v-html="acf.acf.text"></span>
                <br />
                <b-button v-if="id === 4" class="moreBtn" to="/tour-management">Learn More</b-button>
                <b-button v-if="id === 158 || id === 201" class="moreBtn" to="contact-us">Get in touch today</b-button>
              </div>
            </b-col>
            <b-col lg="6" md="12" order-lg="2" order-md="1" order="1">
              <b-embed
              v-if="id === 158"
                type="iframe"
                aspect="16by9"
                :src="acf.acf.video_url"
                allowfullscreen
              ></b-embed>
              <b-img v-else :src="acf.acf.image"></b-img>
            </b-col>
          </b-row>
        </template>

        <template v-if="sec === 'right_1'">
          <b-row>
            <b-col lg="6" md="12" order-lg="1" order-md="2" order="2">
              <div class="textSec">
                <h2 class="textTitle">
                  {{ acf.acf.title_2 }}
                </h2>
                <h3 class="textSub">
                  {{ acf.acf.sub_title_2 }}
                </h3>
                <span class="text" v-html="acf.acf.text_2"></span>
                <br />
                
              </div>
            </b-col>
            <b-col lg="6" md="12" order-lg="2" order-md="1" order="1">
              <b-img :src="acf.acf.image_2"></b-img>
            </b-col>
          </b-row>
        </template>

        <template v-if="sec === 'left'">
          <b-row>
            <b-col lg="6" md="12">
              <b-img
                :class="{ disk: id === 158 }"
                :src="acf.acf.image_1"
              ></b-img>
            </b-col>
            <b-col lg="6" md="12">
              <div class="textSec">
                <h2 class="textTitle">
                  {{ acf.acf.title_1 }}
                </h2>
                <h3 class="textSub">
                  {{ acf.acf.sub_title_1 }}
                </h3>
                <span class="text" v-html="acf.acf.text_1"></span>
                <br />
                <b-button v-if="id === 4" class="moreBtn" to="/venue-management">Learn More</b-button>
              </div>
            </b-col>
          </b-row>
        </template>

        <template v-if="sec === 'left_1'">
          <b-row>
            <b-col lg="6" md="12">
              <b-img :src="acf.acf.image_3"></b-img>
            </b-col>
            <b-col lg="6" md="12">
              <div class="textSec">
                <h2 class="textTitle">
                  {{ acf.acf.title_3 }}
                </h2>
                <h3 class="textSub">
                  {{ acf.acf.sub_title_3 }}
                </h3>
                <span class="text" v-html="acf.acf.text_3"></span>
                <br />
                
              </div>
            </b-col>
          </b-row>
        </template>

        <template v-if="sec === 'centre'">
          <b-row>
            <b-col md="6" cols="12" class="bulletCol leftImg">
              <div>
                <b-img :src="acf.acf.image_2"></b-img>
              </div>
              <b-row>
                <b-col>
                  <div class="bulletSec">
                    <h3 class="textSub">
                      {{ acf.acf.title_2 }}
                    </h3>
                    <span v-html="acf.acf.text_2"></span>
                    <h3 class="textSub">
                      {{ acf.acf.title_2_1 }}
                    </h3>
                    <ul>
                      <li>
                        {{ acf.acf.event_bullet }}
                      </li>
                      <li>
                        {{ acf.acf.event_bullet_1 }}
                      </li>
                      <li>
                        {{ acf.acf.event_bullet_2 }}
                      </li>
                      <li>
                        {{ acf.acf.event_bullet_3 }}
                      </li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" cols="12" class="grey bulletCol rightImg">
              <div>
                <b-img :src="acf.acf.image_3"></b-img>
              </div>
              <b-row>
                <b-col>
                  <div class="bulletSec">
                    <h3 class="textSub">
                      {{ acf.acf.title_3 }}
                    </h3>
                    <span v-html="acf.acf.text_3"></span>
                    <h3 class="textSub">
                      {{ acf.acf.title_3_1 }}
                    </h3>
                    <ul>
                      <li>
                        {{ acf.acf.apps_bullet }}
                      </li>
                      <li>
                        {{ acf.acf.apps_bullet_1 }}
                      </li>
                      <li>
                        {{ acf.acf.apps_bullet_2 }}
                      </li>
                      <li>
                        {{ acf.acf.apps_bullet_3 }}
                      </li>
                      <li>
                        {{ acf.acf.apps_bullet_4 }}
                      </li>
                      <li>
                        {{ acf.acf.apps_bullet_5 }}
                      </li>
                      <li>
                        {{ acf.acf.apps_bullet_6 }}
                      </li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "leftImg",
  props: ["acf", "id", "sec"],
};
</script>

<style scoped>
::v-deep .container {
    max-width: 1400px;
    padding: 0;
}
.textTitle {
  color: #212529;
}
.textSub {
  color: #00aeef;
  font-weight: 300;
}
.text > p {
  font-size: 18px;
}
.moreBtn {
  border-color: transparent;
  font-size: 1.5rem;
  background-color: #00aeef;
  color: #f4f4f6;
  font-weight: 300;
  padding: 10px 75px;
  border-radius: 0;
}
.moreBtn:hover{
  background-color: #003460;
}
.grey {
  background-color: #f4f4f6;
}
.bulletCol {
  padding-left: 0;
  padding-right: 0;
}
.rightImg {
  margin-top: 70px;
  margin-left: -50px;
  z-index: 1;
}
.leftImg {
  z-index: 10;
}
.bulletSec {
  padding: 40px 15px;
  width: 80%;
}
ul {
  padding-left: 20px;
}
li::marker {
  color: #00aeef;
}
.disk {
  margin-top: -100px;
  margin-bottom: -100px;
}
@media only screen and (max-width: 1240px) {
  .disk {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 480px) {
  .rightImg {
    margin-top: 0px;
    margin-left: 0px;
    background-color: #fff;
  }
}
</style>
